import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "../../components/Typography/Typography";
import MainLayout from "../../components/mainlayout";
import Summary from "../../components/CaseStudy/Summary/Summary";
import {
  mobileViewBreakpoint,
  extraLargeDesktopViewBreakpoint
} from "../../components/helper";

const useStyles = makeStyles(theme => ({
  mainContainer: {
    margin: "0px -16px"
  },
  caseStudyContainer: {
    boxSizing: "border-box",
    padding: "0px 32px",
    maxWidth: "1234px",
    margin: "80px auto 0px auto",
    "& > h2": {
      marginBottom: "28px",
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        marginBottom: "24px"
      }
    },
    "& > h5": {
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        marginBottom: "24px"
      }
    },

    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      padding: "0px 16px 28px 16px",
      margin: "80px auto 0px auto"
    }
  }
}));

const Article = () => {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query {
      bannerImage: file(relativePath: { eq: "casestudy/formulae.png" }) {
        childImageSharp {
          fixed(width: 800, height: 800) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <MainLayout
      title={"Ad-Diriyah Seasons, Riyadh"}
      h1
      banner={
        <StaticImage
          src="../../assets/images/casestudy/diriyah.jpg"
          draggable="false"
        />
      }
      breadcrumbs={[{ label: "Ad Diriyah Seasons Riyadh" }]}
      metaTitle="Ad Diriyah Seasons Riyadh Case Study"
      metaDescription="Click to read how MOTUS | ONE was responsible for the first planning cycle that included 15 transport experts who developed the transport support systems."
      shareImage={data.bannerImage.childImageSharp.fixed.src}
    >
      <div className={classes.mainContainer}>
        <Summary
          title={"Overview"}
          fullHeight={false}
          isFlipped={false}
          image={
            <StaticImage
              src="../../assets/images/casestudy/formulae.png"
              draggable="false"
            />
          }
          link={undefined}
          description={
            <>
              Saudi Arabia’s Diriyah Season has hosted many exciting one - off
              events, including concerts, a Formula E Prix and the historic
              professional heavyweight Clash on the Dunes fight. <br />
              <br />
              New is the Diriyah Tennis Cup, in which eight of the world’s best
              players competed for a $3m prize purse over three days of clay -
              court tennis. It was held in the same 15,000 - seat arena that had
              hosted the epic Clash on the Dunes professional heavyweight boxing
              bout just a week earlier.
            </>
          }
        />

        <Summary
          title={"Management"}
          fullHeight={false}
          isFlipped={true}
          image={
            <StaticImage
              src="../../assets/images/casestudy/matchroom.jpg"
              draggable="false"
            />
          }
          link={undefined}
          description={
            <>
              <p>
                MOTUS | ONE was responsible for the first planning cycle that
                included 15 transport experts who developed the transport
                support systems required to deliver the Diriyah Seasons events.
              </p>

              <p>
                We built a fully operational team to deliver the public shuttle
                system, VIP fleet services, load - zone management, talent
                transport (boxing and tennis), staff transport, volunteer
                transport, vehicle tracking, and fleet dispatch systems.
              </p>

              <p>
                We used 600+ staff to manage this event, 75% of whom were local
                Saudi nationals trained by us.
              </p>
            </>
          }
        />

        <Summary
          title={"Challenges"}
          fullHeight={false}
          isFlipped={false}
          image={
            <StaticImage
              src="../../assets/images/casestudy/motusteam-riyadh.jpg"
              draggable="false"
            />
          }
          customHeight={1000}
          customWidth={480}
          link={undefined}
          description={
            <>
              <p>
                The primary challenge revolved around access for the public. The
                wider area is a Unesco heritage site and has minimal parking and
                access for the thousands of guests and attendees.
              </p>
              <p>
                On top of the access challenge was the overlay of the Formula E
                Grand Prix. Although it was part of Diriyah Seasons, it needed
                its own set of access, parking, shuttling, and signage to remain
                satisfy their venue and event requirements.
              </p>
              <br />
              <Typography customVariant={"h2Bold"}>Solutions</Typography>
              <br />

              <>
                <p>
                  We integrated all internal and external stakeholders to create
                  a comprehensive traffic and transport plan using external car
                  parks to mitigate the number of vehicles near the event site.
                </p>

                <p>
                  We also activated internal VIP fleet services to move category
                  - one ticketholders and VIPs throughout the site using a
                  limited number of high - spec vehicles.
                </p>

                <p>
                  Our teams managed the dispatching, loading, and unloading of
                  shuttles and the VIP fleet, with end - to - end tracking of
                  all passengers and vehicles.
                </p>
              </>
            </>
          }
        />
      </div>
    </MainLayout>
  );
};

export default Article;
